import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List, ListItem, MenuItem, Popover, Select, Tooltip } from '@mui/material'
import { AddOutlined, HelpOutlineOutlined } from '@mui/icons-material'

import ButtonComponent from '../components/shared/ButtonComponent'
import GenericTable from '../components/shared/GenericTable/GenericTable'
import { updateSnackbar } from '../redux/snackbarState'
import AddDeposit from '../components/views/forms/AddDeposit'
import { useHistory } from 'react-router'
import FormModalMUI from '../components/shared/Modals/FormModalMUI'

import { listAllTransactions } from '../API/CheckRegisterAPI'
import { getBanks } from '../utils/helper'

const CheckRegister = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const banks = useSelector((state) => state.appData.banks);
	console.log(banks)
	const bankOptions = banks?.data.map((bank) => ({
		key: bank.bankName,
		value: bank._id,
		accountNumber: bank.accountNumber,
		balance: bank.balance,
	}));
	const [selectedBank, setSelectedBank] = useState('');


	useEffect(() => {
		if (bankOptions && bankOptions.length > 0) {
			setSelectedBank(bankOptions[0].value);
		}
	}, [banks]);

	const [isAddPayeeModalOpen, setAddPayeeModalOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [modifiedData, setModifiedData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [anchorEl, setAnchorEl] = useState(null);

	const [currentBalance, setCurrentBalance] = useState();
  console.log(selectedBank,"UpdatedValue")
	useEffect(() => {
		getBanks(dispatch);
		if (bankOptions.length > 0 && selectedBank == '') {
			setSelectedBank(bankOptions[0].value);
			setCurrentBalance(bankOptions[0].balance);
		}
	}, [])



	useEffect(() => {
		if (selectedBank) {
			fetchTransactions(selectedBank);
		}
	}, [selectedBank]);

	const openAddPayee = () => {
		setAddPayeeModalOpen(true);
	};

	const closeAddPayee = async () => {
		setAddPayeeModalOpen(false);
		setAnchorEl(null);
		await getBanks(dispatch);
		await fetchTransactions(selectedBank);
	};

	const fetchTransactions = async (accountNumber) => {
		try {
			setIsLoading(true);
			const response = await listAllTransactions(accountNumber);
			console.log(response,"Response is here")
			const transactions = response.data;
			makeTableData(transactions);
			setIsLoading(false);
			setCurrentBalance(getBalance(accountNumber));
		} catch (error) {
			setIsLoading(false);
			dispatch(updateSnackbar({ open: true, message: 'Failed to fetch transactions.', severity: 'error' }));
		}
	};

	function amountFix(amount){
		return amount
			? amount < 0 && amount !== 0
				? `-$${Math.abs(amount).toFixed(2)}`
				: `$${amount?.toFixed(2)}`
			: `$${amount?.toFixed(2)}`
	}

	const getBalance = (accountNumber) => {
		const filteredAccounts = bankOptions.filter(account => account.value === accountNumber);
		return filteredAccounts.length > 0 ? filteredAccounts[0].balance : null;
	};

	const makeTableData = (data) => {
		const temp = data.map((item) => {
			const date = new Date(item.issue_date);
			return {
				payeeName: item.payee_name,
				description: item.description,
				checkNumber: item.check_no,
				deposit: {
					value: item.amount,
					html: (
						<div
							style={{
								backgroundColor: '#FFFFF',
								color: '#06cb52',
								fontSize: '12px',
								fontWeight: '500',
								borderRadius: '6px'
							}}
							disabled={true}
						>
							{(item.amount && item.type == "deposit")
								? `${amountFix(item.amount)}`
								: ''}
						</div>
					)
				},
				balance: amountFix(item.balance),
				status: {
					value: item.status,
					html: (
						<>
							{(item.status !== '' && item.type == "transaction") ? (
								<div
									className={'check-status-div d-flex flex-row align-items-center py-2 px-2 rounded justify-content-center text-capitalize mx-auto'}
									style={{
										width: '50%',
										backgroundColor:
											item.status === 'Paid'
												? '#bef5b5'
												: item.status === 'Open'
													? '#4dc3db73'
													: '#FFF1F1',
										color: '#FFFFF',
										fontSize: '10px',
										fontWeight: '500',
										borderRadius: '6px',
										border: '1px solid #EEE',
										lineHeight: '1'
									}}
									disabled={true}
								>
									{item.status}
								</div>
							) : (
								<div
									className={'check-status-div d-flex flex-row align-items-center py-3 px-2 rounded justify-content-center text-capitalize mx-auto'}
									style={{
										height: '25%',
										width: '50%',
										color: '#FFFFF',
										fontSize: '10px',
										fontWeight: '500'
									}}
									disabled={true}
								>

								</div>
							)}
						</>
					)
				},
				issuedDate: `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`,
				amount: {
					value: item.amount,
					html: (
						<div
							style={{
								backgroundColor: '#FFFFF',
								color: '#DE0202',
								fontSize: '12px',
								fontWeight: '500',
								borderRadius: '6px'
							}}
							disabled={true}
						>
							{(item.amount && item.type == "transaction")
								? `${amountFix(item.amount)}`
								: ''}
						</div>
					)
				}
			};
		});

		setModifiedData([...temp]);
		setFilteredData([...temp]);
		setIsLoading(false);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const columnData = [
		{ key: 'Issue date', value: 'issuedDate', colWidth: '9%', align: 'left' },
		{ key: 'Check #', value: 'checkNumber', colWidth: '10%', align: 'center' },
		{ key: 'Payee Name', value: 'payeeName', colWidth: '10%', align: 'center' },
		{ key: 'Description', value: 'description', colWidth: '15%', align: 'center' },
		{ key: 'Status', type: 'html', value: 'status', colWidth: '10%', align: 'center' },
		{ key: 'Check Amount', type: 'html', value: 'amount', colWidth: '10%', align: 'center' },
		{ key: 'Deposit', type: 'html', value: 'deposit', colWidth: '12%', align: 'center' },
		{ key: 'Balance', type: 'html', value: 'balance', colWidth: '12%', align: 'center' },
	];

	console.log(selectedBank,"selectedBank")

	const getBalanceColor = (balance) => {
		return balance < 0 ? 'red' : 'green';
	};

	const getCurrentBalance = () => {
		return selectedBank === bankOptions[0]?.value ? bankOptions[0]?.balance : currentBalance;
	};

	const currentBalanceValue = getCurrentBalance();
	return (
		<div className="container">
			<div className="page-header d-flex align-items-center justify-content-between mb-4 pb-4">
				<div className="d-flex align-items-center justify-content-start">
					<h3 className="fs-5 fw-semibold m-0" style={{ color: '#12221fbf' }}>
						Account /
					</h3>
					<Select
						sx={{
							boxShadow: 'none',
							'.MuiOutlinedInput-notchedOutline': { border: 0 },
							'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
								borderColor: 'white',
							},
							'& .MuiSelect-select': {
								paddingTop: '10px',
								paddingBottom: '10px',
								display: 'flex',
								alignItems: 'center',
							},
						}}
						MenuProps={{
							sx: {
								'&& .Mui-selected': {
									backgroundColor: '#EAFBE7',
								},
								'&& .Mui-focused': {
									backgroundColor: '#FFF8DE',
								},
							},
						}}
						value={selectedBank}
						onChange={(e) => {
							setSelectedBank(e.target.value);
						}}

					>
						{bankOptions.map((bank, index) => (
							<MenuItem key={index} value={bank.value} className="menu-item" >
								<h3 className="fs-6 fw-normal m-0" style={{ color: '#12221fbf' }}>
									{bank.key}
								</h3>
							</MenuItem>
						))}
					</Select>
				</div>
				<Tooltip
					title={
						<span>
              Please contact at{' '}
							<a href="mailto:support@synccos.com" target="blank" rel="no-referrer" className="fw-semibold text-white">
                support@synccos.com
              </a>{' '}
							for queries and support
            </span>
					}
				>
					<HelpOutlineOutlined />
				</Tooltip>
			</div>
			<div className="d-flex align-items-center justify-content-between mt-4">
				<p className="regular-txt fs-18 mb-0">
					Current Balance: <span style={{ color: getBalanceColor(currentBalanceValue) }}>{amountFix(currentBalanceValue)}</span>
				</p>
				<div className="d-flex align-items-center justify-content-center">
					<ButtonComponent text="Add Transactions" variant="dark" icon={<AddOutlined />} type="button" click={handleClick} />
					<Popover
						id={anchorEl ? 'simple-popover' : undefined}
						open={Boolean(anchorEl)}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						sx={{
							top: '15px',
							left: '-25px',
						}}
					>
						<List style={{ minWidth: '190px' }}>
							<ListItem
								className="list-item-new"
								onClick={openAddPayee}
								key="adddeposit"
								sx={{
									marginTop: '10px',
									padding: '6px 15px',
									justifyContent: 'start',
									alignItems: 'center',
									display: 'flex',
									fontSize: '14px',
								}}
							>
								Add Deposit
							</ListItem>
							<ListItem
								className="list-item-new"
								onClick={() => {
									history.push('/dashboard/create-check');
								}}
								key="transactions"
								sx={{
									padding: '16px 15px',
									justifyContent: 'start',
									alignItems: 'center',
									display: 'flex',
									fontSize: '14px',
								}}
							>
								Create Transaction
							</ListItem>
						</List>
					</Popover>
				</div>
			</div>
			<div className="generic-table-container">
				<GenericTable
					columnData={columnData}
					modifiedData={modifiedData}
					isLoading={isLoading}
					initialfilter=""
					clickable={false}
					filteredData={filteredData}
					setFilteredData={setFilteredData}
					noDataProps={{
						text: 'All the Transactions created will be stored here',
						head: 'No Transactions',
					}}
				/>
			</div>
			<FormModalMUI title="Add Deposit" open={isAddPayeeModalOpen} maxWidth="sm" onClose={closeAddPayee}>
				<AddDeposit onClose={closeAddPayee} />
			</FormModalMUI>
		</div>
	);
};

export default CheckRegister;
