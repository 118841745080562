import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import FormComponents from '../../shared/forms'
import FormModal from '../../shared/Modals/FormModal'
import ButtonComponent from '../../shared/ButtonComponent'
import { addAddress, updateAddress } from '../../../API/UserAPI'
import { useDispatch, useSelector } from 'react-redux'
import { updateSnackbar } from '../../../redux/snackbarState'
import countryData from '../../../utils/countryStateCity.json'
import FormModalMUI from '../../shared/Modals/FormModalMUI'
import { ErrorOutlineOutlined } from '@mui/icons-material'
import { getAddresses, getOrganizations } from '../../../utils/helper'
import {
	addOrganization,
	updateOrganization
} from '../../../API/OrganizationAPI'

const AddAddress = ({
	isModalOpen,
	closeModal,
	setDirty,
	showWarning,
	setWarning
}) => {
	const dispatch = useDispatch()

	const [initialValues, setInitialValues] = useState(null)

	const initial_addresses = useSelector((state) => state.appData.addresses)
  console.log(initial_addresses,"Initial Is HEre")
	const [addresses, setAddresses] = useState(new Map())
	const addItem = () => {
		setAddresses([...addresses]) // Add a new item to the array
	}

	const organizationData = useSelector((state) => state.appData.organizations)
	const [organizationData1, setOrgizationData] = useState()
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)
	console.log(selectedOrganization, 'Organization Data')
	// console.log(organizationData1,"OrganizationData is here proper")
	useEffect(() => {
		let result = organizationData.data.filter((data) => {
			return data?._id === selectedOrganization
		})
		// console.log("OrganizationData",result)
		if (result.length > 0) {
			setOrgizationData(result[0])
		} else {
			// console.log('');
			setOrgizationData()
		}
	}, [organizationData, selectedOrganization])

	 console.log(organizationData1,"organizationData121")

	const [isDisabled, setIsDisabled] = useState(true)
	const [isNoAddressData, setIsNoAddressData] = useState(true)
	const [btnTitle, setBtnTitle] = useState('')

	const validationSchema = Yup.object({
		zipCode: Yup.number().required('Required !'),
		country: Yup.string().required('Required !'),
		state: Yup.string().required('Required !'),
		addressLine1: Yup.string().required('Required !'),
		// addressLine2: Yup.string().required('Required !'),
		city: Yup.string().required('Required !'),
		name: Yup.string().required('Required !')
	})

	// useEffect(() => {
	// 	// if (/* add your condition here */) {
		
	// 	// }
	// 	if(organizationData1 !== undefined){
	// 			setInitialValues({
	// 			addressLine1: '123 Street',
	// 			addressLine2: 'Apt 101',
	// 			city: 'New York',
	// 			country: 'USA',
	// 			state: 'NY',
	// 			zipCode: '10001',
	// 			name: 'John Doe'
	// 		});
	// 		console.log("Defined")
	// 	 }else{
	// 		console.log("Undefined")
	// 	 }
	// }, [initial_addresses]);



	useEffect(() => {
		setInitialValues({
			addressLine1: '',
			addressLine2: '',
			city: '',
			country: '',
			state: '',
			zipCode: '',
			name: ''
		})

		 
		if (initial_addresses && !initial_addresses?.isLoading) {
			if (initial_addresses && initial_addresses.data.length !== 0) {
				for (let i = 0; i < initial_addresses.data.length; i++) {
					console.log(initial_addresses,"Addresses Is Here")
					console.log(selectedOrganization,"SelectedOrganization")
					// if(selectedOrganization == null){
						let temp = {
							name: initial_addresses.data[i].name || '',
							addressLine1: initial_addresses.data[i]?.addressLine1 || '',
							addressLine2: initial_addresses.data[i]?.addressLine2 || '',
							city: initial_addresses.data[i]?.city || '',
							country: initial_addresses.data[i]?.country || '',
							state: initial_addresses.data[i]?.state || '',
							zipCode: initial_addresses.data[i]?.zipCode || '',
							_id: initial_addresses.data[i]?._id || ''
						}
						addresses.set(initial_addresses.data[i]?.name, temp)
					// }else{
					// 	let temp = {
					// 		name: organizationData1.name || '',
					// 		addressLine1: organizationData1.addressLine1 || '',
					// 		addressLine2: organizationData1.addressLine2 || '',
					// 		city: organizationData1.city || '',
					// 		country: organizationData1.country || '',
					// 		state: organizationData1.state || '',
					// 		zipCode: organizationData1.zipCode || '',
					// 		_id: organizationData1._id || ''
					// 	}
					// 	addresses.set(organizationData1.name, temp)
					// }
					
				}
			}
		}

		if (addresses.size == 0) {
			setBtnTitle('Add Address')
		} else {
			setBtnTitle('Change Address')
		}
	}, [initial_addresses])

	const onSubmit = async (values) => {
		try {
			let res
			setIsNoAddressData(false)
			console.log(values, 'valkues')
			// for (let i = 0; i < initial_addresses.data.length; i++) {
			// 	let temp = {
			// 		addressLine1: values?.addressLine1 || '',
			// 		addressLine2: values?.addressLine2 || '',
			// 		city: values?.city || '',
			// 		country: values?.country || '',
			// 		state: values?.state || '',
			// 		zipCode: values?.zipCode || ''
			// 	}
			// 	addresses.set(values?.name, temp)
			// }
			//   HERE I ADDED COMMENT
			console.log(organizationData1, 'OrganizationData 11')
			if (organizationData1 !== undefined) {
				console.log('THis Condition called')
				const keys = Object.keys(values)

				let body = new FormData()

				keys.forEach((key) => {
					// body.append(key, values[key])
					body.append(key === 'zipCode' ? 'zip' : key, values[key])
				})

				console.log(body)

				res = await updateOrganization(selectedOrganization, body)
				if (addresses.size === 0) {
					await addAddress({ ...values })
				}else{
					let id = [...addresses][0][1]?._id
					res = await updateAddress({ ...values }, id)
				}
				if (res.status === 200) {
					// Check for 200 status
					dispatch(
						updateSnackbar({
							open: true,
							message: 'Organization Address Added Successfully',
							severity: 'success'
						})
					)
					closeModal(true)
				} else {
					// Handle error case
					dispatch(
						updateSnackbar({
							open: true,
							message: 'Failed to add Organization Address.',
							severity: 'error'
						})
					)
					closeModal(true)
				}
			} else if (addresses.size === 0) {
				res = await addAddress({ ...values })
				// console.log('THis Condition called  add address')
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Address Added successfully',
						severity: 'success'
					})
					
				)
				closeModal(true)
			} else {
				let id = [...addresses][0][1]?._id
				res = await updateAddress({ ...values }, id)
				dispatch(
					updateSnackbar({
						open: true,
						message: 'Address Updated successfully',
						severity: 'success'
					})
				)
				closeModal(true)
			}

			// if (organizationData1 === undefined) {
			// 	console.log("organizationData1 is undefined");
			// } else {
			// 	console.log("organization is selected")
			// }

			// if (addresses.size == 0) {
			// 	res = await addAddress({ ...values })
			// 	dispatch(
			// 		updateSnackbar({
			// 			open: true,
			// 			message: 'Address Added successfully',
			// 			severity: 'success'
			// 		})
			// 	)
			// } else {
			// 	let id = [...addresses][0][1]?._id
			// 	res = await updateAddress({ ...values }, id)
			// 	dispatch(
			// 		updateSnackbar({
			// 			open: true,
			// 			message: 'Address Updated successfully',
			// 			severity: 'success'
			// 		})
			// 	)
			// }

			getAddresses(dispatch)
			// organizationData(dispatch)
			getOrganizations(dispatch)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Unable to Update.',
					severity: 'error'
				})
			)
		}
	}

	return (
		<>
			{initialValues && isModalOpen ? (
				<Formik
					initialValues={ organizationData1 !== undefined ?  {
						name: organizationData1.organizationName,
						addressLine1: organizationData1.addressLine1,
						addressLine2: organizationData1.addressLine2,
						city: organizationData1.city,
						country: organizationData1.country,
						state: organizationData1.state,
						zipCode: organizationData1.zip

						// addressLine1: '',
						// addressLine2: '',
						// city: '',
						// country: '',
						// state: '',
						// zipCode: ''
				  } :
						addresses.size > 0
							? [...addresses][0][1]
							: {
									name: '',
									addressLine1: '',
									addressLine2: '',
									city: '',
									country: '',
									state: '',
									zipCode: ''
							  }
					}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					enableReinitialize
				>
					{({ handleSubmit, dirty, values }) => {
						return (
							<FormModalMUI
								onClose={() => closeModal(false)}
								open={isModalOpen}
								title={btnTitle}
								maxWidth="md"
								onSave={onSubmit}
							>
								<Form>
									{setDirty(dirty)}
									{showWarning && (
										<div class="alert alert-danger" role="alert">
											<div className="row row justify-content-between">
												<div className="col-auto p-2">
													<ErrorOutlineOutlined className="me-2" />
													Are you sure you want to close the modal? Your details
													wont be saved.
												</div>
												<div className="col-auto">
													<div className="row justify-content-end">
														<div className="col-auto">
															<button
																type="button"
																class="btn btn-light"
																onClick={() => setWarning(false)}
															>
																No
															</button>
														</div>
														<div className="col-auto">
															<button
																type="button"
																class="btn btn-danger"
																onClick={() => closeModal(true)}
															>
																Yes
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}
									<div className="row">
										<div className="col-12 col-md-4">
											<FormComponents
												name="name"
												type="text"
												label="Save As (eg. Home)"
												control="input"
												// disabled={addresses.size > 0}
												// disabled
												// initial_addresses.data[i]?.addressLine1
												// disabled={initial_addresses.data[0]?.addressLine1 !== null}
												disabled={organizationData1 !== undefined ? organizationData1.addressLine1 : initial_addresses.data[0]?.hasOwnProperty('addressLine1')}
												// disabled={values.addressLine1 !== ''}
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4">
											<FormComponents
												name="addressLine1"
												type="text"
												label="Address Line 1"
												control="input"
												// disabled={isDisabled}
											/>
										</div>
										<div className="col-12 col-md-4">
											<FormComponents
												name="addressLine2"
												type="text"
												label="Address Line 2"
												control="input"
												// disabled={isDisabled}
											/>
										</div>
										<div className="col-12 col-md-4">
											<FormComponents
												name="country"
												label="Country"
												control="autocomplete"
												options={[
													...new Set(
														countryData?.flatMap((obj) => {
															return {
																key: obj?.name,
																value: obj?.name
															}
														})
													)
												]}
												disabled={values?.state !== ''}
												required
											/>
										</div>
									</div>
									<div className="row">
										<div className="col-12 col-md-4">
											<FormComponents
												name="state"
												label="State"
												control="autocomplete"
												options={
													values?.country !== ''
														? [
																...new Set(
																	countryData
																		?.filter(
																			(country) =>
																				country?.name === values?.country
																		)[0]
																		?.states?.flatMap((obj) => {
																			return {
																				key: obj?.name,
																				value: obj?.name
																			}
																		})
																)
														  ]
														: []
												}
												disabled={values?.city !== ''}
												required
											/>
										</div>
										<div className="col-12 col-md-4">
											<FormComponents
												name="city"
												label="City"
												control="autocomplete"
												options={
													values?.country !== '' && values?.state !== ''
														? [
																...new Set(
																	countryData
																		?.filter(
																			(country) =>
																				country?.name === values?.country
																		)[0]
																		?.states?.filter(
																			(state) => state?.name === values?.state
																		)[0]
																		?.cities?.flatMap((obj) => {
																			return {
																				key: obj,
																				value: obj
																			}
																		})
																)
														  ]
														: []
												}
												required
											/>
										</div>
										<div className="col-12 col-md-4">
											<FormComponents
												name="zipCode"
												type="number"
												label="ZIP"
												control="input"
												// disabled={isDisabled}
											/>
										</div>
									</div>
									<div className="d-flex align-items-center justify-content-center mt-3 mb-5">
										<ButtonComponent
											text="Cancel"
											type="button"
											variant="light"
											click={() => closeModal(false)}
											extraClass="me-3"
										/>
										<ButtonComponent
											text="Save Changes"
											type="submit"
											variant="dark"
											// click={() => {
											// 	handleSubmit()
											// 	closeModal(true)
											// }}
											disabled={dirty ? false : true}
										/>
									</div>
								</Form>
							</FormModalMUI>
						)
					}}
				</Formik>
			) : null}
		</>
	)
}

export default AddAddress
