import { CorporateFare, EditOutlined } from '@mui/icons-material'
import { Icon, IconButton } from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'
import FormComponents from '../shared/forms'
import ButtonComponent from '../shared/ButtonComponent'
import countryData from '../../utils/countryStateCity.json'
import { useDispatch } from 'react-redux'
import { updateSnackbar } from '../../redux/snackbarState'
import { addOrganization, updateOrganization } from '../../API/OrganizationAPI'
import { getOrganizations } from '../../utils/helper'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { addAddress, updateAddress } from '../../API/UserAPI'
import Cookies from 'js-cookie'

const CompanyForm = ({ data, isUpdate }) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [image, setImage] = useState(data?.organizationLogo || null)
	const [isImageTouched, setImageTouched] = useState(false)

	const [initialValues, setInitialValues] = useState({
		organizationName: data?.organizationName || '',
		addressLine1: data?.addressLine1 || '',
		addressLine2: data?.addressLine2 || '',
		city: data?.city || '',
		state: data?.state || '',
		country: data?.country || '',
		entityType: data?.entityType || '',
		zip: data?.zip || '',
		dba: data?.dba || '',
		formationDate: data?.formationDate
			? new Date(data?.formationDate)
			: new Date(),
		industryType: data?.industryType || '',
		ein: data?.ein || ''
	})

	useEffect(() => {
		setImage(data?.organizationLogo)
		setInitialValues({
			organizationName: data?.organizationName || '',
			addressLine1: data?.addressLine1 || '',
			addressLine2: data?.addressLine2 || '',
			city: data?.city || '',
			state: data?.state || '',
			country: data?.country || '',
			entityType: data?.entityType || '',
			zip: data?.zip || '',
			dba: data?.dba || '',
			formationDate: data?.formationDate || new Date(),
			industryType: data?.industryType || '',
			ein: data?.ein || ''
		})
	}, [data])

	const fileInputRef = useRef(null)

	const entityTypes = [
		{ key: 'Sole Proprietor', value: 'Sole Proprietor' },
		{ key: 'Single Member LLC', value: 'Single Member LLC' },
		{ key: 'Limited Liability Company', value: 'Limited Liability Company' },
		{ key: 'General Partnership', value: 'General Partnership' },
		{ key: 'Unlisted Corporation', value: 'Unlisted Corporation' },
		{
			key: 'Publicly Traded Corporation',
			value: 'Publicly Traded Corporation'
		},
		{ key: 'Association', value: 'Association' },
		{ key: 'Non Profit', value: 'Non Profit' },
		{ key: 'Government Organization', value: 'Government Organization' },
		{ key: 'Revocable Trust', value: 'Revocable Trust' },
		{ key: 'Irrevocable Trust', value: 'Irrevocable Trust' },
		{ key: 'Estate', value: 'estate' },
		{ key: 'Other', value: 'other' }
	]

	const industryTypes = [
		{ key: 'Retail', value: 'Retail' },
		{ key: 'Wholesale', value: 'Wholesale' },
		{ key: 'Restaurants', value: 'Restaurants' },
		{ key: 'Hospitals', value: 'Hospitals' },
		{ key: 'Construction', value: 'Construction' },
		{ key: 'Insurance', value: 'Insurance' },
		{ key: 'Unions', value: 'Unions' },
		{ key: 'Real Estate', value: 'Real Estate' },
		{ key: 'Freelance Professional', value: 'Freelance Professional' },
		{
			key: 'Other Professional Services',
			value: 'Other Professional Services'
		},
		{ key: 'Online Retailer', value: 'Online Retailer' },
		{ key: 'Other Education Services', value: 'Other Education Services' },
		{ key: 'Other', value: 'Other' }
	]
	const [isAddressFilled, setIsAddressFilled] = useState(false)
	const handleAddressChange = (e, setFieldValue, validateForm) => {
		// const { name, value } = e.target;
		// setFieldValue(name, value);  // Update the current field value
		// validateForm();  // Revalidate the form after the value is updated
		if (e && e.target) {
			const { name, value } = e.target  // For regular input elements
			setFieldValue(name, value)  // Update the field value
		} else {
			// This branch handles cases where 'e' is a custom event (like from a dropdown)
			// setFieldValue('city', e);  // Set the 'city' value directly
		}
		validateForm()
	}

	const isAnyAddressFieldFilled = (values) => {
		return (
			values.addressLine1 ||
			values.addressLine2 ||
			values.city ||
			values.state ||
			values.country ||
			values.zip
		)
	}

	// const validationSchema = Yup.object({
	// 	organizationName: Yup.string().required('Required'),
	// 	addressLine1: Yup.string(),
	// 	addressLine2: Yup.string(),
	// 	city: Yup.string(),
	// 	state: Yup.string(),
	// 	// country: Yup.string(),
	// 	country: Yup.string().test('country-required', 'Country is required', function (value) {
	// 		return isAddressFilled ? !!value : true;  // Validate based on state
	// 	  }),
	// 	entityType: Yup.string(),
	// 	zipCode: Yup.string(),
	// 	dba: Yup.string(),
	// 	formationDate: Yup.date(),
	// 	industryType: Yup.string(),
	// 	ein: Yup.string()
	// })

	const validationSchema = Yup.object({
		organizationName: Yup.string().required('Required'),
		addressLine1: Yup.string().test(
			'address-required',
			'All address fields are required.',
			function(value) {
				const { addressLine2, city, state, country, zipCode } = this.parent
				return isAnyAddressFieldFilled({
					addressLine1: value,
					addressLine2,
					city,
					state,
					country,
					zipCode
				}) ? !!value : true
			}
		),
		addressLine2: Yup.string().test(
			'address-required',
			'All address fields are required.',
			function(value) {
				const { addressLine1, city, state, country, zipCode } = this.parent
				return isAnyAddressFieldFilled({
					addressLine1,
					addressLine2: value,
					city,
					state,
					country,
					zipCode
				}) ? !!value : true
			}
		),
		city: Yup.string().test(
			'address-required',
			'All address fields are required.',
			function(value) {
				const { addressLine1, addressLine2, state, country, zipCode } = this.parent
				return isAnyAddressFieldFilled({
					addressLine1,
					addressLine2,
					city: value,
					state,
					country,
					zipCode
				}) ? !!value : true
			}
		),
		state: Yup.string().test(
			'address-required',
			'All address fields are required.',
			function(value) {
				const { addressLine1, addressLine2, city, country, zipCode } = this.parent
				return isAnyAddressFieldFilled({
					addressLine1,
					addressLine2,
					city,
					state: value,
					country,
					zipCode
				}) ? !!value : true
			}
		),
		country: Yup.string().test(
			'address-required',
			'All address fields are required.',
			function(value) {
				const { addressLine1, addressLine2, city, state, zipCode } = this.parent
				return isAnyAddressFieldFilled({
					addressLine1,
					addressLine2,
					city,
					state,
					country: value,
					zipCode
				}) ? !!value : true
			}
		),
		zip: Yup.string().test(
			'address-required',
			'All address fields are required.',
			function(value) {
				const { addressLine1, addressLine2, city, state, country } = this.parent
				return isAnyAddressFieldFilled({
					addressLine1,
					addressLine2,
					city,
					state,
					country,
					zipCode: value
				}) ? !!value : true
			}
		),
		dba: Yup.string(),
		formationDate: Yup.date(),
		industryType: Yup.string(),
		ein: Yup.string()
	})


	const handleChange = async (event) => {
		if (event?.target?.files.length === 0) {
			return false
		}

		const file = event?.target?.files[0]

		setImage(file)
		if (file?.size > 5000000) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'info',
					message: 'Image more that 5MB is not allowed.'
				})
			)
			return false
		}

		const reader = new FileReader()

		reader.onload = function(e) {
			document.getElementById('organization-logo').src = e.target.result
			setImageTouched(true)
		}

		reader.readAsDataURL(file)
	}

	const handleButtonClick = () => {
		fileInputRef.current.click()
	}

	const onSubmit = async (values, touched) => {
		const keys = Object.keys(values)
		console.log(values)

		let body = new FormData()

		keys.forEach((key) => {
			body.append(key, values[key])
		})
		let formDataObject = {}
		for (let [key, value] of body.entries()) {
			formDataObject[key] = value
		}

		console.log(formDataObject)
		console.log(body)

		if (image && typeof image !== 'string') {
			body.append('organizationLogo', image)
		}
		try {
			let res
			if (isUpdate) {
				res = await updateOrganization(data?._id, formDataObject)
				if (!Cookies.get('organization')) {
					Cookies.set('organization', data?._id)
					Cookies.set('customOrganization', true)
				}
				let addressId = data?.addresses[0]?._id
				if(addressId){
					await updateAddress({
						"name": values['organizationName'],
						"addressLine1": values['addressLine1'],
						"addressLine2": values['addressLine2'],
						"city": values['city'],
						"country": values['country'],
						"state": values['state'],
						"zipCode": values['zip']
					}, addressId)
				}else{
					await addAddress({
						"name": values['organizationName'],
						"addressLine1": values['addressLine1'],
						"addressLine2": values['addressLine2'],
						"city": values['city'],
						"country": values['country'],
						"state": values['state'],
						"zipCode": values['zip']
					});
				}
				if (Cookies.get('customOrganization') === true) {
					Cookies.remove('organization')
					Cookies.remove('customOrganization')
				}
				setInitialValues({
					organizationName: res?.data?.organizationName || '',
					addressLine1: res?.data?.addressLine1 || '',
					addressLine2: res?.data?.addressLine2 || '',
					city: res?.data?.city || '',
					state: res?.data?.state || '',
					country: res?.data?.country || '',
					entityType: res?.data?.entityType || '',
					zip: res?.data?.zip || '',
					dba: res?.data?.dba || '',
					formationDate: res?.data?.formationDate || new Date(),
					industryType: res?.data?.industryType || '',
					ein: res?.data?.ein || ''
				})
			} else {
				console.log('THis One is calling')
				console.log(values);
				res = await addOrganization(body)
				if (!Cookies.get('organization')) {
					Cookies.set('organization', res.data._id)
					Cookies.set('customOrganization', true)
				}
				await addAddress({
					"name": values['organizationName'],
					"addressLine1": values['addressLine1'],
					"addressLine2": values['addressLine2'],
					"city": values['city'],
					"country": values['country'],
					"state": values['state'],
					"zipCode": values['zip']
				});
				if (Cookies.get('customOrganization') === true) {
					Cookies.remove('organization')
					Cookies.remove('customOrganization')
				}
				getOrganizations(dispatch)
				history.push('/dashboard/manage-organizations')
			}
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'success',
					message: isUpdate
						? 'Organization updated successfully.'
						: 'Organization created successfully.'
				})
			)
		} catch (err) {
			dispatch(
				updateSnackbar({
					open: true,
					severity: 'error',
					message: isUpdate
						? 'Unable to update organization.'
						: 'Unable to create organization.'
				})
			)
		}
	}

	console.log('isImageTouched', isImageTouched)

	return (
		<>
			<div className="company-form-row mb-3">
				<div className="row m-0">
					<p className="fs-6 mb-4 fw-semibold">Business Logo</p>

					<div className="image-upload-div position-relative d-flex align-items-center justify-content-center">
						<Icon
							sx={{
								width: 'auto',
								height: 'auto'
							}}
						>
							{image && image !== '' ? (
								<img
									src={image}
									style={{
										width: '110px',
										height: '110px',
										borderRadius: '55px',
										color: '#fff'
									}}
									alt="organization-logo"
									id="organization-logo"
								/>
							) : (
								<CorporateFare
									sx={{
										width: '3rem',
										height: '3rem',
										color: '#fff'
									}}
								/>
							)}
						</Icon>
						<input
							type="file"
							id="file-input"
							ref={fileInputRef}
							style={{ display: 'none' }}
							onChange={handleChange}
							accept="image/png, image/jpeg, image/jpg"
						/>
						<IconButton
							sx={{
								color: '#757575',
								backgroundColor: '#F5F5F5',
								position: 'absolute',
								right: '0',
								bottom: '10px'
							}}
							onClick={handleButtonClick}
						>
							<EditOutlined
								sx={{
									width: '14px',
									height: '14px'
								}}
							/>
						</IconButton>
					</div>
				</div>
			</div>
			<div>
				<p
					className="fs-6 mb-4 fw-semibold"
					style={{ marginLeft: '12px', marginTop: '10px' }}
				>
					Basic Details
				</p>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					enableReinitialize={true}
					onSubmit={onSubmit}
				>
					{({ values, touched, isValid, dirty, setFieldValue, validateForm, handleSubmit }) => {
						return (
							<Form>
								<div className="row m-0">
									<div className="col-12">
										<FormComponents
											control="input"
											type="text"
											name="organizationName"
											label="Organization Name"
											required
										/>
									</div>
								</div>
								<div className="row m-0">
									<div className="col-12 col-md-6">
										<FormComponents
											control="input"
											type="text"
											name="addressLine1"
											label="Address Line 1"
											onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponents
											control="input"
											type="text"
											name="addressLine2"
											label="Address Line 2"
											onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
										/>
									</div>
								</div>
								<div className="row m-0">
									<div className="col-6 col-md-6 col-lg-3">
										<FormComponents
											control="input"
											type="text"
											name="city"
											label="City"
											onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
										/>
										{/* <FormComponents
												name="city"
												label="City"
												control="autocomplete"
												options={
													values?.country !== '' && values?.state !== ''
														? [
																...new Set(
																	countryData
																		?.filter(
																			(country) =>
																				country?.name === values?.country
																		)[0]
																		?.states?.filter(
																			(state) => state?.name === values?.state
																		)[0]
																		?.cities?.flatMap((obj) => {
																			return {
																				key: obj,
																				value: obj
																			}
																		})
																)
														  ]
														: []
												}
												onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
											/> */}
									</div>
									<div className="col-6 col-md-6 col-lg-3">
										<FormComponents
											control="input"
											type="text"
											name="state"
											label="State"
											onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
										/>
										{/* <FormComponents
												name="state"
												label="State"
												control="autocomplete"
												options={
													values?.country !== ''
														? [
																...new Set(
																	countryData
																		?.filter(
																			(country) =>
																				country?.name === values?.country
																		)[0]
																		?.states?.flatMap((obj) => {
																			return {
																				key: obj?.name,
																				value: obj?.name
																			}
																		})
																)
														  ]
														: []
												}
												disabled={values?.city !== ''}
												onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
											/> */}
									</div>
									<div className="col-6 col-md-6 col-lg-3">
										<FormComponents
											control="input"
											type="text"
											name="country"
											label="Country"
											onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
										/>
										{/* <FormComponents
												name="country"
												label="Country"
												control="autocomplete"
												options={[
													...new Set(
														countryData?.flatMap((obj) => {
															return {
																key: obj?.name,
																value: obj?.name
															}
														})
													)
												]}
												disabled={values?.state !== ''}
												
											/> */}
									</div>
									<div className="col-6 col-md-6 col-lg-3">
										<FormComponents
											control="input"
											type="text"
											name="zip"
											label="Zip Code"
											onChange={(e) => handleAddressChange(e, setFieldValue, validateForm)}
										/>
									</div>
								</div>
								<div className="row m-0">
									<div className="col-12 col-md-6">
										<FormComponents
											name="entityType"
											label="Entity Type"
											control="select"
											options={entityTypes}
										/>
									</div>
									<div className="col-12 col-md-6">
										<FormComponents
											control="input"
											type="text"
											name="dba"
											label="DBA"
										/>
									</div>
								</div>
								<div className="row m-0">
									<div className="col-12 col-md-4">
										<FormComponents
											name="formationDate"
											label="Formation Date"
											control="date"
										/>
									</div>
									<div className="col-12 col-md-4">
										<FormComponents
											name="industryType"
											label="Industry Type"
											control="select"
											options={industryTypes}
										/>
									</div>
									<div className="col-12 col-md-4">
										<FormComponents
											control="input"
											type="text"
											name="ein"
											label="EIN"
										/>
									</div>
								</div>
								<div className="row m-0">
									<div className="d-flex align-items-center justify-content-end">
										<ButtonComponent variant="light" text="Cancel" />
										<ButtonComponent
											variant="dark"
											text="Save Changes"
											extraClass="ms-3"
											type="submit"
											//disabled={!isValid || !dirty || isImageTouched}
											// click={() => {
											// 	onSubmit(values, touched)
											// }}
											// onClick={(e) => {
											// 	e.preventDefault(); // Prevent the default form submit behavior
											// 	if (isValid && dirty) {
											// 	  handleSubmit();  // Formik's submit function will only submit if the form is valid
											// 	} else {
											// 	  console.log("Validation errors exist. Please fix them before submitting.");
											// 	}
											//   }}
										/>
									</div>
								</div>
							</Form>
						)
					}}
				</Formik>
			</div>
		</>
	)
}

export default CompanyForm
