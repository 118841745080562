import React from 'react'
import Cookies from 'js-cookie'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
	const isLoggedin = Cookies.get('user-status') ? true : false

	// const hasSubscription = Cookies.get('subscription') === 'true'
	// console.log(hasSubscription,"Has Subscription")
	return (
		<Route
			{...rest}
			render={(props) =>
				isLoggedin === true  
				// && hasSubscription 
				? 
				(
					<Component {...props} />
				) : (
					<Redirect
						to={{ pathname: '/auth/login', state: { from: props.location } }}
					/>
				)
			}
		/>
	)
}

export default PrivateRoute