import { postRequest } from '.'

const getEmailLinks = async (body) => {
	console.log(body,"Get Email Link is here")
	const res = await postRequest('/checks/check/linkGenUpload', body)
	return res
}

const sendEmail = async (body) => {
	console.log(body,"Email Send LInk is here")

	const res = await postRequest('/checks/check/sendEmailWithCheck', body)
	return res
}

const sendCheckEmails = async (body) => {
	// console.log(body,"Email Send LInk is here")

	const res = await postRequest('/checks/send-check-details', body, {
		headers: {
			'Content-Type': 'multipart/form-data' // Set the content type to multipart/form-data
		}
	});
	return res;
}

export { getEmailLinks, sendEmail,sendCheckEmails}
