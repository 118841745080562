import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import ButtonComponent from '../../shared/ButtonComponent'
import AddAddress from '../forms/AddAddress'

const Address = () => {
	// const [validationSchema, setValidationSchema] = useState(null)
	const [isModalOpen, setModalOpen] = useState(false)

	const openModal = () => {
		setModalOpen(true)
	}

	const [btnTitle, setBtnTitle] = useState('')
	const initial_addresses = useSelector((state) => state.appData.addresses)
	const [isDirty, setIsDirty] = useState(false)
	const [showWarning, setWarning] = useState(false)

	const [addresses, setAddresses] = useState(new Map())

	const setDirty = (dirty) => {
		setIsDirty(dirty)
	}

	const closeModal = (forced) => {
		if (isDirty && !forced) {
			setWarning(true)
		} else {
			setModalOpen(false)
			setWarning(false)
		}
	}
	const organizationData = useSelector((state) => state.appData.organizations)
	const [organizationData1, setOrgizationData] = useState()
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)
	useEffect(() => {
		// addresses.clear();
        let result = organizationData.data.filter((data) => {
            return data?._id === selectedOrganization
        })
		console.log(organizationData1,"OrganizationData")
		if (organizationData1 !== undefined){
			let temp = {
				name: organizationData1?.organizationName || '',
				addressLine1: organizationData1?.addressLine1 || '',
				addressLine2: organizationData1?.addressLine2 || '',
				city: organizationData1?.city || '',
				country: organizationData1?.country || '',
				state: organizationData1?.state || '',
				zipCode: organizationData1?.zipCode || '',
				_id: organizationData1?._id || ''
			}
			
			// addresses.set(organizationData1?.organizationName, temp)
		}

        if(result.length > 0) {
			setOrgizationData(result[0]);

        } else {
            // console.log('');
			setOrgizationData();
			
        }
    }, [ selectedOrganization]);

	useEffect(() => {

		// if (organizationData1 !== undefined){
		// 	addresses.clear();
		// 	let temp = {
		// 		name: organizationData1?.organizationName || '',
		// 		addressLine1: organizationData1?.addressLine1 || '',
		// 		addressLine2: organizationData1?.addressLine2 || '',
		// 		city: organizationData1?.city || '',
		// 		country: organizationData1?.country || '',
		// 		state: organizationData1?.state || '',
		// 		zipCode: organizationData1?.zipCode || '',
		// 		_id: organizationData1?._id || ''
		// 	}
		// 	addresses.set(organizationData1?.organizationName, temp)
		// }else {
			// addresses.clear();
			if (initial_addresses && !initial_addresses?.isLoading) {
				if (initial_addresses && initial_addresses?.data?.length !== 0) {
					for (let i = 0; i < initial_addresses.data.length; i++) {
						let temp = {
							name: initial_addresses.data[i]?.name || '',
							addressLine1: initial_addresses.data[i]?.addressLine1 || '',
							addressLine2: initial_addresses.data[i]?.addressLine2 || '',
							city: initial_addresses.data[i]?.city || '',
							country: initial_addresses.data[i]?.country || '',
							state: initial_addresses.data[i]?.state || '',
							zipCode: initial_addresses.data[i]?.zipCode || '',
							_id: initial_addresses.data[i]?._id || ''
						}
						addresses.set(initial_addresses.data[i]?.name, temp)
					}
				} else {
					setAddresses(new Map())
				}
			}
			if (addresses.size == 0) {
				setBtnTitle('Add Address')
			} else {
				setBtnTitle('Change Address')
			}
		// }
		
	}, [initial_addresses])
  

	console.log(organizationData1,"DATA IS HERE ID CHANGED")
	return (
		<>
			<div className="container-fluid p-0">
				<div className="row justify-content-between">
					<div className="col-9">
						<h4 className="fs-6 fw-semibold text-black mt-4 mb-2">Addresses</h4>
					</div>
					<div className="col-3 mt-4">
						<ButtonComponent
							text={btnTitle}
							variant="dark"
							onClick={openModal}
							extraClass="me-0 ms-auto"
						/>
					</div>
				</div>
			</div>

			{addresses.isLoading ? null : (
				<AddAddress
					isModalOpen={isModalOpen}
					setWarning={setWarning}
					closeModal={closeModal}
					setDirty={setDirty}
					showWarning={showWarning}
				/>
			)}
     {organizationData1 == undefined ? addresses.size === 0 ? (
				<div>
					<p>No address data available with us.</p>
				</div>
			) : (
				<div className="container-fluid">
					{[...addresses.keys()].map((key) => (
						<div className="row">
							<div className="col-4 ps-0">
								<small className="text-secondary">
									<b>Nickname</b>
								</small>

								<p className="mb-0 fs-14">{key}</p>
							</div>
							<div className="col-auto">
								<small className="ps-0 text-secondary">
									<b>Address</b>
								</small>

								<p className="mb-0 fs-14">
									{addresses.get(key).addressLine1 +
										', ' +
										addresses.get(key).addressLine2}
								</p>

								<p className="mb-0 fs-14">
									{addresses.get(key).city + ', ' + addresses.get(key).state}
								</p>

								<p className="mb-0 fs-14">
									{addresses.get(key).country +
										', ' +
										addresses.get(key).zipCode}
								</p>
							</div>
						</div>
					))}
				</div>
			) : <><div className="row">
			<div className="col-4 ps-0">
				<small className="text-secondary">
					<b>Nickname</b>
				</small>

				<p className="mb-0 fs-14">{organizationData1?.organizationName || null}</p>
				{/* addressLine1: organizationData1?.addressLine1 || '',
				addressLine2: organizationData1?.addressLine2 || '',
				city: organizationData1?.city || '',
				country: organizationData1?.country || '',
				state: organizationData1?.state || '',
				zipCode: organizationData1?.zipCode || '',
				_id: organizationData1?._id || '' */}
			</div>
			<div className="col-auto">
				<small className="ps-0 text-secondary">
					<b>Address</b>
				</small>

				<p className="mb-0 fs-14">
					{organizationData1?.addressLine1 ? organizationData1?.addressLine1 : null+
						', ' +
						organizationData1?.addressLine2 ? organizationData1?.addressLine2 : null}
				</p>

				<p className="mb-0 fs-14">
					{organizationData1?.city ? organizationData1?.city : null + ', ' + organizationData1?.state ? organizationData1?.state : null}
				</p>

				<p className="mb-0 fs-14">
					{organizationData1?.country +
						', ' +
						organizationData1?.zipCode ?  organizationData1?.zipCode : null  }
				</p>
			</div>
		</div> </> }
			
		</>
	)
}

export default Address
