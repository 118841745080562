import React, { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { Button } from '@mui/material'
import { appleIcon, googleIcon, microsoftIcon } from '../../assets/svg'
import {
	signUpGoogle,
	appleSignUp,
	getGoogleInfo,
	signInGoogle,
	appleSignIn
} from '../../API/socialAPI'
import { useMsal } from '@azure/msal-react'
import jwt_decode from 'jwt-decode'
//import { loginRequest } from '../../utils/Providers/microsoftAuth'

import AppleLogin from 'react-apple-login'
import { useDispatch } from 'react-redux'
import { login } from '../../redux/loginLogout'
import { updateSnackbar } from '../../redux/snackbarState'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import axios from 'axios'
import { checkUser } from '../../API/UserAPI'

const SocialLogin = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const location = useLocation()

	const [googleUserData, setGoogleUserData] = useState(null)

	//instance for google login
	const googleLogin = useGoogleLogin({
		onSuccess: async (tokenResponse) => {
			const { access_token } = tokenResponse

			///API call to get user data fromm google
			axios
				.get(
					`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
					{
						headers: {
							Authorization: `Bearer ${access_token}`,
							Accept: 'application/json'
						}
					}
				)
				.then(async (res) => {
					const { data } = res

					//setting google data for later pass on to create profile if user not exist
					setGoogleUserData({ ...data })

					//Calling function to check if user exist
					userExist(
						data?.email,
						'google',
						{
							googleAuthToken: access_token
						},
						{
							firstName: data?.given_name,
							lastName: data?.family_name,
							email: data?.email
						}
					)
				})
				.catch((err) => {
					dispatch(
						updateSnackbar({
							open: true,
							message: 'Google sign-in failed.',
							severity: 'error'
						})
					)
				})
		},
		onError: (error) => console.log('GOOGLE ERROR', error)
	})

	//instance for apple login
	const appleResponse = async (response) => {
		if (!response.error) {
			//API call to get auth tokens of apple
			axios
				.post('/auth', response)
				.then((res) => {
					const auth = JSON.parse(res?.config?.data)

					///Object having auth tokens ( id_token, code)
					const { authorization } = auth
					const { id_token } = authorization
					console.log('apple auth object', authorization)

					///decoding apple auth token to get email from object
					const decodedData = jwt_decode(id_token)

					console.log('decoded data apple', decodedData)

					///callling function to check if user exist
					userExist(
						decodedData?.email,
						'apple',
						{
							appleAuthToken: id_token,
							appleAuthCode: authorization.code
						},
						{ email: decodedData?.email }
					)
				})
				.catch((err) => console.log('err', err))
		}
	}

	///Function to check if user exist
	const userExist = async (email, loginType, body, userData = {}) => {
		try {
			const res = await checkUser(email)

			///Success if user already exist

			if (loginType === 'google') {
				try {
					const resp = await signInGoogle(body)

					const authData = { ...resp?.data }
					const domain = window.location.hostname === 'localhost' ? 'localhost' : '.synccos.com';

					Cookies.set('refresh-token', authData.refreshToken, {
						expires: 1
					})

					// Cookies.set('subscription', authData.subscriptionTaken, {
					// 	expires: 1 / 24
					// })
					Cookies.set('subscription', authData.subscriptionTaken, {
						expires: 1 / 24
					})

					Cookies.set('direct-token', authData.accessToken, {
						expires: 1 / 24,
						domain: domain,
					})

					Cookies.set('direct-token-ref', "checkwriter", {
						expires: 1 / 24,
						domain: domain,
					})
					Cookies.set('user-status', true, {
						expires: 1
					})
					Cookies.set('access-token', authData.accessToken, {
						expires: 2 / 24
					})
					
					dispatch(login({ token: authData.accessToken }))
					dispatch(
						updateSnackbar({
							open: true,
							message: `${
								location.pathname.includes('/login')
									? 'Google login successful.'
									: 'Google Signup successful.'
							}`,
							severity: 'success'
						})
					)

								//  Updated Code of Date 13-11-2024
								
								// history.push({
								// 	pathname: '/dashboard/main',
								// 	state: {
								// 		showModal: true
								// 	}
								// })

							//  Updated Code of Date 13-11-2024
							if (authData.subscriptionTaken) {
								dispatch(
									updateSnackbar({
										open: true,
										message: 'Login Sucessful.',
										severity: 'success'
									})
								)
								history.push({
									pathname: '/dashboard/main',
									state: {
										showModal: true
									}
								});
							} else {
								history.push('/subscription');
							}
					localStorage.setItem('isFirstTimeSignUp', false)
				} catch (err) {}
			} else if (loginType === 'apple') {
				try {
					const resp = await appleSignIn(body)

					const authData = { ...resp?.data }
					const domain = window.location.hostname === 'localhost' ? 'localhost' : '.synccos.com';
					Cookies.set('refresh-token', authData.refreshToken, {
						expires: 1
					})


					Cookies.set('subscription', authData.subscriptionTaken, {
						expires: 12 / 24
					})

					Cookies.set('direct-token', authData.accessToken, {
						expires: 1 / 24,
						domain: domain,
					})

					Cookies.set('direct-token-ref', "checkwriter", {
						expires: 1 / 24,
						domain: domain,
					})
					Cookies.set('user-status', true, {
						expires: 1
					})
					Cookies.set('access-token', authData.accessToken, {
						expires: 2 / 24
					})
					dispatch(login({ token: authData.accessToken }))
					dispatch(
						updateSnackbar({
							open: true,
							message: `${
								location.pathname.includes('/login')
									? 'Apple login successful.'
									: 'Apple Signup successful.'
							}`,
							severity: 'success'
						})
					)
					history.push('/dashboard/main')
					localStorage.setItem('isFirstTimeSignUp', false)
				} catch (error) {}
			}
		} catch (error) {
			const { response } = error

			////Throughs error if user doesn't exist
			if (response?.data && response?.data === 'User not found') {
				if (loginType === 'google') {
					///Redirecting to create profile page if user doesn't exist with google data
					history.push({
						pathname: '/auth/create-profile',
						state: {
							authData: {
								...body
							},
							userData: {
								...userData
							},
							loginType
						}
					})
				} else if (loginType === 'apple') {
					//Here need to pass to create profile page with apple data

					//Problem need another api support so that user data can be get from backend
					console.log('apple login tokens', body)
					history.push({
						pathname: '/auth/create-profile',
						state: {
							authData: {
								...body
							},
							userData: {
								...userData
							},
							loginType
						}
					})
				}
			} else {
				//In case api fails for another reason
				dispatch(
					updateSnackbar({
						open: true,
						message: `Fail to verify user.`,
						severity: 'error'
					})
				)
			}
		}
	}

	const { instance } = useMsal()

	// const microsoftLogin = (loginType) => {
	// 	if (loginType === 'popup') {
	// 		instance.loginPopup(loginRequest).catch((e) => {
	// 			console.log(e)
	// 		})
	// 	} else if (loginType === 'redirect') {
	// 		instance.loginRedirect(loginRequest).catch((e) => {
	// 			console.log(e)
	// 		})
	// 	}
	// }

	return (
		<div className="social-login">
			<Button
				className="social-btn d-flex flex-row align-items-center justify-content-center b" sx={{ borderRadius: '14px !important' }}
				onClick={googleLogin}
			>
				<span className="icon me-2">{googleIcon}</span>
				Sign in with Google
			</Button>
			{/* <Button
				className="social-btn d-flex flex-row align-items-center justify-content-center"
				onClick={() => microsoftLogin('popup')}
			>
				<span className="icon me-2">{microsoftIcon}</span>
				Sign in with Microsoft
	</Button>*/}
			{/* <AppleLogin
				clientId="com.synccoscheck.auth.client"
				redirectURI={`${window.location?.href}`}
				callback={appleResponse}
				onSuccess={appleResponse}
				onError={(error) => console.log('AppleSignInBtn onError error', error)}
				scope="email name"
				responseType="code id_token"
				usePopup={true}
				responseMode="form_post"
				render={(props) => {
					return (
						<Button
							className="social-btn d-flex flex-row align-items-center justify-content-center"
							onClick={props.onClick}
						>
							<span className="icon me-2">{appleIcon}</span>
							Sign in with Apple
						</Button>
					)
				}}
			/> */}
		</div>
	)
}

export default SocialLogin
