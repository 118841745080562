///Packages components
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'

///Custom components used
import FormComponents from '../components/shared/forms'
import PageHeader from '../components/shared/PageHeader'
import ButtonComponent from '../components/shared/ButtonComponent'
import { useHistory } from 'react-router-dom';
////Redux functions used
import { updateSnackbar } from '../redux/snackbarState'

import FormModalMUI from '../components/shared/Modals/FormModalMUI'
import { ContentCopyOutlined } from '@mui/icons-material'
import { generatePaymentLink,getUserStripe } from '../API/TransactionAPI'
import { Alert } from '@mui/material'


const CreatePaymentLinks = () => {
	const userData = useSelector((state) => state.appData.userData)
	const history = useHistory();
	const [data, setdata] = useState()

	const [isLinkModalOpen, setLinkModalOpen] = useState(false)
	const [paymentLink, setPaymentLink] = useState('')

	const dispatch = useDispatch()

	const validationSchema = Yup.object().shape({
		recipientEmail: Yup.string().email('Incorrect Email').required('Required'),
		recipientName: Yup.string().required('Required!'),
		amount: Yup.number().required('Required!'),
		invoiceNumber: Yup.string().required('Required'),
		purpose: Yup.string().required('Required')
	})

	const initialValues = {
		recipientEmail: '',
		recipientName: '',
		invoiceNumber: '',
		amount: '',
		currency: 'USD',
		purpose: '',
		dueDate: new Date()
	}

	const openLinkModal = () => {
		setLinkModalOpen(true)
	}

	const closeLinkModal = async () => {
		setLinkModalOpen(false)
		setPaymentLink('')
	}

	const copyLink = () => {
		// Get the text field
		let text = document.getElementById('payment-link')
		const range = document.createRange()
		range.selectNode(text)
		const selection = window.getSelection()
		selection.removeAllRanges()
		selection.addRange(range)

		// Execute the copy command
		document.execCommand('copy')

		// Clean up the selection
		selection.removeAllRanges()

		// Alert the copied text
		dispatch(
			updateSnackbar({
				open: true,
				severity: 'info',
				message: 'Payment link copied'
			})
		)
	}

	const onSubmit = async (values) => {
		try {
			const res = await generatePaymentLink({
				...values,
				email: userData?.data?.email
			})
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Payment Link generated.',
					severity: 'success'
				})
			)
			const { data } = res
			setPaymentLink(data?.payment_link)
			openLinkModal()
		} catch (error) {
			dispatch(
				updateSnackbar({
					open: true,
					message: 'Unable to generate payment link.',
					severity: 'error'
				})
			)
		}
	}


	useEffect(() => {
		fetchpayments()
	}, [])

	
	const fetchpayments = async () => {
		try {
			const res = await getUserStripe(userData?.data?.email)
			console.log(res.data,"response")
			setdata(res.data)
		} catch (error) {
			console.log("error while fetching stripe details")
			// dispatch(
			// 	updateSnackbar({
			// 		open: true,
			// 		severity: 'error',
			// 		message: 'Unable to fetch Stripe Account Details.'
			// 	})
			// )
		}
	}


	return (
		<>
			<div className="container">
				<PageHeader
					text={'Create Payment Link'}
					info="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
				/>
				{data?.stripeAccountId == null && (
					<Alert
						severity="info"
						className="mb-4 d-flex align-items-center w-100"
						sx={{
							'.MuiAlert-message': {
								width: '100%'
							}
						}}
					>
						<div className="d-flex align-items-center justify-content-between">
							<p className="m-0 fs-6" style={{ color: '#161617;' }}>
							You need to integrate a stripe account to create a payment link.
							</p>
							<button
								className="fw-semibold m-0 fs-6 border-0 bg-transparent p-0"
								onClick={() => history.push('/dashboard/integrations/payments')}
								style={{
									color: '#0439EA'
								}}
							>
								Add Stripe Account
							</button>
						</div>
					</Alert>
			 ) } 

				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={onSubmit}
					enableReinitialize={true}
				>
					{({ handleSubmit, values, resetForm, isValid, dirty }) => {
						return (
							<>
								<Form onSubmit={handleSubmit}>
									<div className="row p-0 mx-0 my-2">
										<div className="col-12 col-md-4 px-3">
											<div className="d-flex align-items-start justify-content-center w-100">
												<FormComponents
													name="recipientName"
													type="text"
													label="Recipient Name"
													control="input"
													required
												/>
											</div>
										</div>
										<div className="col-12 col-md-4 px-3">
											<FormComponents
												name="recipientEmail"
												type="email"
												label="Recipient Email"
												control="input"
												required
											/>
										</div>
										<div className="col-12 col-md-4 px-3">
											<div className="d-flex align-items-start">
												<div className="field-prefix">$</div>
												<FormComponents
													name="amount"
													type="number"
													label="Amount"
													control="input"
													prefix={true}
													required
												/>
											</div>
										</div>
									</div>
									<div className="row p-0 mx-0 my-2">
										<div className="col-12 col-md-4 px-3">
											<FormComponents
												name="invoiceNumber"
												type="number"
												label="Invoice Number"
												control="input"
												required
											/>
										</div>
										<div className="col-12 col-md-4 px-3">
											<FormComponents
												name="dueDate"
												label="Due Date"
												control="date"
												required
											/>
										</div>
										<div className="col-12 col-md-4 px-3">
											<FormComponents
												name="purpose"
												type="text"
												label="Purpose"
												control="input"
												required
											/>
										</div>
									</div>
									<div className="row p-0 mx-0 my-0">
										<div className="col-12">
											<div className="d-flex justify-content-between">
												<div>
													<ButtonComponent
													    disabled={data?.stripeAccountId == null ? true :  false}
														text="Create Link"
														variant="light"
														type="button"
														onClick={() => {
															onSubmit(values)
														}}
														extraClass="me-3"
													/>
												</div>
											</div>
										</div>
									</div>
								</Form>
							</>
						)
					}}
				</Formik>
			</div>

			<FormModalMUI
				title={'Payment Link Generated'}
				onClose={closeLinkModal}
				open={isLinkModalOpen}
				maxWidth="sm"
			>
				<div>
					<p className="fs-6 fw-semibold text-secondary text-center mb-2">
						Link sent to recipient Email Successfully!!
					</p>
					<p
						className="text-center text-primary mb-4 mt-0 fs-14"
						id="payment-link"
						style={{
							wordWrap: 'break-word'
						}}
					>
						{paymentLink}
					</p>
				</div>

				<div className="d-flex align-items-center justify-content-around mb-4">
					<ButtonComponent
						text="Copy Link"
						icon={<ContentCopyOutlined />}
						variant="light"
						onClick={copyLink}
					/>
					<ButtonComponent
						text="Close"
						variant="dark"
						onClick={closeLinkModal}
					/>
				</div>
			</FormModalMUI>
		</>
	)
}

export default CreatePaymentLinks
