import React, { useEffect, useState } from 'react'
import { stripeLogo } from '../../assets/svg/IntegrationsSvg'
import { Button, CircularProgress, Icon } from '@mui/material'
import {
	deleteStripeAccount,
	getStripeAccounts,
	stripeIntegrate,
	getQuickBookAccount,
	deleteQuickBookAccount
} from '../../API/IntegrationsAPI'
import { updateSnackbar } from '../../redux/snackbarState'
import { useSelector,useDispatch } from 'react-redux'
import { CheckCircle, WidthFull } from '@mui/icons-material'
import ButtonComponent from '../../components/shared/ButtonComponent'
import quickbook from '../../assets/images/quickbook.png'
import FormModalMUI from '../../components/shared/Modals/FormModalMUI'
import AddQuickBook from '../../components/views/forms/Addquickbook'

const Payments = () => {
	const dispatch = useDispatch()
	const userData = useSelector((state) => state.appData.userData)
	const selectedOrganization = useSelector(
		(state) => state.appData.selectedOrganization
	)
	const [isLoading, setLoading] = useState(false)
	const [haveAccount, setHaveAccount] = useState(false)
	const [quickAccount, setQuickAccount] = useState(false)
	const [isDisabled, setIsDisabled] = useState(false)

	const integrateStripe = async () => {
		try {
			const res = await stripeIntegrate(userData?.data?.email)
			const { data } = res
			// window.open(data?.url, '_self')
			window.open(data?.url, '_blank');
		} catch (error) {
			debugger
		}
	}

	useEffect(() => {
		fetchAccounts()
	}, [userData])

	useEffect(() => {
		fetchQuick()
	}, [userData,selectedOrganization])

	const fetchAccounts = async () => {
		try {
			const res = await getStripeAccounts(userData?.data?.email)
			console.log(res?.data,"Data is here")
			if (res?.data) {
				
				setHaveAccount(true)
				setLoading(false)
			} else {
				setLoading(false)
			}
		} catch (error) {
			// console.log(error,"Data is here")
			setLoading(false)
			setHaveAccount(false)
			setIsDisabled(true)
		}
	}


	const fetchQuick = async () => {
		try {
			let body = {}
			if (selectedOrganization && selectedOrganization !== "No Org") {
				body.userId = selectedOrganization;
			} else {
				body.userId = userData.data._id; // Assuming userId is available in the scope
			}
			const res = await getQuickBookAccount(body)
			console.log(res?.data,"Data is here")
			if (res?.data) {
				setQuickAccount(res?.data.quickBooks)
				setLoading(false)
			} else {
				setLoading(false)
			}
		} catch (error) {
			setQuickAccount(false)
			setLoading(false)
			setIsDisabled(true)
		}
	}



	const deleteQuick = async () => {
		   let body = {}
			if (selectedOrganization && selectedOrganization !== "No Org") {
				body.userId = selectedOrganization;
			} else {
				body.userId = userData.data._id; // Assuming userId is available in the scope
			}
		try {
			const res = await deleteQuickBookAccount(body)
			console.log(res.data)
			if(res?.data.message == "Account details deleted successfully"){
				setQuickAccount(false)
				dispatch(
					updateSnackbar({
						open: true,
						message:  'Account Removed Successfully',
						severity: 'warning'
					})
				)
			}
		} catch (error) {
			setIsDisabled(true)
		}
	}

	const deleteAccount = async () => {
		try {
			const res = await deleteStripeAccount(userData?.data?.email)
			setHaveAccount(false)
		} catch (error) {
			setIsDisabled(true)
		}
	}
	// Model Code is here
	const [isAddPayeeModalOpen, setAddPayeeModalOpen] = useState(false)

	const openAddPayee = () => {
		setAddPayeeModalOpen(true)
	}

	const closeAddPayee = async () => {
		setAddPayeeModalOpen(false)
		// setAnchorEl(null);
		// await getBanks(dispatch);
		// await fetchTransactions(selectedBank);
	}
  

	console.log(quickAccount,"Quick Account Is here")
	return (
		<div className="">
			{isLoading ? (
				<div
					className="d-flex align-items-center justify-content-center"
					style={{
						height: '500px'
					}}
				>
					<CircularProgress color="success" />
				</div>
			) : (
				<>
					{/* <div className="integration-grid">
					<div className="row m-0 p-0">
						<div className="col-12 col-md-4 col-lg-2">
							<div
								className=" position-relative integration-div d-flex align-items-center justify-content-center flex-column"
								sx={{
									'&.MuiButtonBase-root': {
										'&:hover': {
											backgroundColor: 'rgba(0, 128, 0, 0.04)'
										},
										color: '#12221f'
									}
								}}
							>
								<Icon
									className="position-absolute d-flex"
									sx={{
										fontSize: '1rem',
										color: 'green',
										top: '10px',
										right: '10px'
									}}>
									{haveAccount ? (
										<CheckCircle
											sx={{
												fontSize: 'inherit'
											}}
										/>
									) : null}
								</Icon>
								<div className="d-flex align-items-start justify-content-start">
								<div className="logo">{stripeLogo}</div>
								</div>
								<ButtonComponent
									text={haveAccount ? 'Remove' : 'Add'}
									variant="dark"
									extraClass="px-2 py-1 mt-2"
									onClick={() => {
										if (haveAccount) {
											deleteAccount()
										} else {
											integrateStripe()
										}
									}}
								/>
							</div>
						</div>
					</div>
				</div> */}
					<div className="row">
						<div class="col-4">
							<div class=" position-relative integration-div p-3 shadow p-3 mb-5 bg-body rounded">
								<span
									class="material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium position-absolute d-flex css-1ddj3s7"
									aria-hidden="true"
								></span>
								<Icon
									className="position-absolute d-flex"
									sx={{
										fontSize: '1rem',
										color: 'green',
										top: '10px',
										right: '10px'
									}}
								>
									{haveAccount ? (
										<CheckCircle
											sx={{
												fontSize: 'inherit'
											}}
										/>
									) : null}
								</Icon>
								<div class="d-flex align-items-center justify-content-start">
									<div class="logo" style={{ width: '10px' }}>
										{stripeLogo}
									</div>
								</div>
								<p class="fs-14 mb-3">
									Stripe is a payment processing platform that lets your
									business safely and effectively accept online and credit card
									payments.
								</p>
								<ButtonComponent
									text={haveAccount ? 'Remove' : 'Add'}
									variant="dark"
									extraClass="px-2 py-1 mt-2"
									onClick={() => {
										if (haveAccount) {
											deleteAccount()
										} else {
											integrateStripe()
										}
									}}
								/>
							</div>
						</div>
						<div class="col-4">
							<div
								class=" position-relative integration-div p-3 shadow p-3 mb-5 bg-body rounded"
								style={{ minHeight: '80%' }}
							>
								<span
									class="material-icons notranslate MuiIcon-root MuiIcon-fontSizeMedium position-absolute d-flex css-1ddj3s7"
									aria-hidden="true"
								></span>
								<Icon
									className="position-absolute d-flex"
									sx={{
										fontSize: '1rem',
										color: 'green',
										top: '10px',
										right: '10px'
									}}
								>
							 {quickAccount ? (
										<CheckCircle
											sx={{
												fontSize: 'inherit'
											}}
										/>
									) : null}
								</Icon>
								<div class="d-flex align-items-center justify-content-start">
								<img
										class="logo"
										style={{ width: '42%' }}
										src={quickbook}
										alt=""
										loading="lazy" // Add this attribute for lazy loading
									/>
								</div>
								<p class="fs-14 mb-3">
									Quickbooks is Online accounting software that gives you peace
									of mind
								</p>
								<ButtonComponent
									// text={'Add'}
									text={quickAccount ? 'Remove' : 'Add'}
									variant="dark"
									extraClass="px-2 py-1 mt-2"
									onClick={quickAccount ? deleteQuick : openAddPayee}
									// onClick={() => {
									// 	if (haveAccount) {
									// 		deleteAccount()
									// 	} else {
									// 		integrateStripe()
									// 	}
									// }}
								/>
							</div>
						</div> 
					</div>
					 <FormModalMUI
						title="Connect QuickBooks with CheckWriter - Steps to follow"
						open={isAddPayeeModalOpen}
						maxWidth="md"
						onClose={closeAddPayee}
					>
						<AddQuickBook onClose={closeAddPayee} />
					</FormModalMUI> 
				</>
			)}
		</div>
	)
}

export default Payments
